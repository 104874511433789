html, body {
  background-color: #eee;
}

.card-container-home {
  margin: 6rem auto;
}

img {
  flex-shrink: 0;
}

.call-to-action-home {
  padding: 1rem 2rem;
  border-radius: 25px;
  font-size: 1.75rem;
  color: #000;
  width: 188px;
  display: block;
  text-align: center;
  margin-bottom: 3rem;
  font-weight: bold;
  margin-left: auto;
  margin-right: auto;
}
.call-to-action-home:hover {
  color: #fff;
  text-decoration: none;
}

.card .card-title {
  color: #000;
  font-weight: 900;
  font-size: 3.25rem;
}
.card .card-subtitle {
  font-weight: 500;
  font-size: 1.35rem;
  margin-bottom: 2rem;
}
.card .card-text, .card .card-text ul li {
  color: #666;
  font-size: 1rem;
}
.card .card-text ul li {
  margin-bottom: 1rem;
}
.card .card-body {
  padding: 3rem;
}
.card .card-footer {
  background-color: inherit;
  border: none;
}